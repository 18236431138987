import { useEffect } from "react";

//styles
import "./CardItem.scss"

export default function ({ id, title, image, sizeOptions, colorOptions }) {
  //aquí vamos a consultar las prendas de la categoría, que vienen desde el padre

  useEffect(() => {
    
  }, []);

  return (
    <div className="cardItemContainer">
      <div className="cardItem">

        {/* izquierda */}
        <div className="imageContainer">
          <img src={image} />
        </div>




        {/* derecha */}
        <div className="infoContainer">
          <h3>{title}</h3>


          <div className="sizesContainer">
            {sizeOptions.map(size => <span>{size}</span>)}
          </div>


          <div className="colorsContainer">
            {colorOptions.map(color => <span style={{borderColor: color.hex}}>{color.name}</span>)}
          </div>
        </div>

      </div>
    </div>
  );
}
