import React from "react";

//styles
import "./Home.scss";


export default function ({ setRouter, clientProps, setClientProps }) {
  const goToCotizar = () => {
    setRouter("/cotizar")
  }
  const goToEvento = () => {
    setRouter("/evento")
  }
  const goToExit = () => {
    window.location.href = 'http://uniformes-unire.com/';
  };

  return (
    <div className="Home">
      <div className="space"></div>

      <div className="hero">
        <h1>El futuro de los uniformes</h1>
      </div>

      <div className="actions">
        <button className="cotizar" onClick={goToCotizar}>Cotización</button>
        <button className="evento" onClick={goToEvento}>Mi Evento</button>
        <button className="salir" onClick={goToExit}>
          Salir
        </button>
      </div>
    </div>
  );
}
