//react
import React, { useEffect, useState } from "react";

// components
import CardCategory from "../../components/CardCategory/CardCategory";

//styles
import "./Cotizar.scss";

//fake data
const Caballeros = [
  {
    id: "19302-38244-2145421",
    category: "Playera.polo",
    categoryTitle: "Playera polo",

    
    items: [
      {
        id: "Polo 1",
        title: "Polo 1",
        image:
          "https://soulandblues.com/cdn/shop/products/A040042_bd3699e5-69f9-4c67-932b-312dcf687c93.jpg?v=1607026195&width=1920",
        sizeOptions: ["S", "M", "L", "XL"],
        colorOptions: [
          { name: "Rojo", hex: "#FF0000" },
          { name: "Azul Marino", hex: "#000080" },
        ],
      },
      {
        id: "Polo 2",
        title: "Polo 2",
        image:
          "https://i.pinimg.com/736x/3b/1e/ca/3b1eca97e7b1cf6b448f450500b43b1a.jpg",
        sizeOptions: ["S", "M", "L", "XL"],
        colorOptions: [
          { name: "Rojo", hex: "#FF0000" },
          { name: "Azul Marino", hex: "#000080" },
        ],
      },
    ],
  },
  {
    id: "29302-38244-2145421",
    category: "Camisa.manga.larga",
    categoryTitle: "Camisa manga larga",

    items: [
      { 
        id: "Camisa manga larga 1",
        title: "Camisa manga larga 1",
        image: "https://www.shutterstock.com/image-vector/gentle-men-black-polo-long-600nw-2273431447.jpg",
        sizeOptions: ["S", "M", "L", "XL"],
        colorOptions: [
          { name: "Rojo", hex: "#FF0000" },
          { name: "Azul Marino", hex: "#000080" },
        ],
      },
      { 
        id: "Camisa manga larga 2",
        title: "Camisa manga larga 2",
        image: "https://cdn-images.farfetch-contents.com/24/11/59/79/24115979_54480396_600.jpg",
        sizeOptions: ["S", "M", "L", "XL"],
        colorOptions: [
          { name: "Rojo", hex: "#FF0000" },
          { name: "Azul Marino", hex: "#000080" },
        ],
      },
    ]
  },
];

const Damas = [
  {
    id: "09302-38244-2145421",
    category: "Blusa.manga.larga",
    categoryTitle: "Blusa manga larga",

    items: [
      { 
        id: "Blusa manga larga 1",
        title: "Blusa manga larga 1",
        image: "https://img.freepik.com/fotos-premium/camisa-blanca-manga-larga_739536-6724.jpg",
        sizeOptions: ["XS", "S", "M", "L"],
        colorOptions: [
          { name: "Azul", hex: "#0000FF" },
          { name: "Azul Marino", hex: "#000080" },
        ],
      },
      { 
        id: "Blusa manga larga 2",
        title: "Blusa manga larga 2",
        image: "https://image.hm.com/assets/hm/84/5a/845adb8a0f8f5741e1437783df805caf8180c67c.jpg?imwidth=2160",
        sizeOptions: ["XS", "S", "M", "L"],
        colorOptions: [
          { name: "Azul", hex: "#0000FF" },
          { name: "Azul Marino", hex: "#000080" },
        ],
      },
      
    ]
  },

  {
    id: "49302-38244-2145421",
    category: "Blusa.manga.corta",
    categoryTitle: "Blusa manga corta",

    items: [
      {
        id: "Blusa manga corta 1",
        title: "Blusa manga corta 1",
        image:
          "https://optimamayoreo.com.mx/wp-content/uploads/2023/08/35000-00207.jpg",
        sizeOptions: ["XS", "S", "M", "L"],
        colorOptions: [
          { name: "Azul", hex: "#0000FF" },
          { name: "Azul Marino", hex: "#000080" },
        ],
      },
      {
        id: "Blusa manga corta 2",
        title: "Blusa manga corta 2",
        image:
          "https://s.alicdn.com/@sc04/kf/A2b231e6f75004f83b1ce0a92cb5eeac2J.jpg_300x300.jpg",
        sizeOptions: ["XS", "S", "M", "L"],
        colorOptions: [
          { name: "Azul", hex: "#0000FF" },
          { name: "Azul Marino", hex: "#000080" },
        ],
      },
    ],
  },
];

export default function ({ setRouter, clientProps, setClientProps }) {
  
  const [pedidoProps, setPedidoProps] = useState({
    empresa: "Chubb",
    evento: "Verano 2025",

    caballero: [
      
    ],

    dama: [
      
    ],
  });

  useEffect(() => {
    //aqui consumimos todas estas opciones de nuestra base de datos
    //  Caballero
    // Camisa manga larga
    // Camisa manga corta
    // Playera polo
    // Pantalón tipo dockers
    //  Dama
    // Blusa manga larga
    // Blusa manga corta
    // Playera polo
    // Pantalón
    // una vez consumidas vamos a plantearlas en un objeto, que pasaremos a mapear por categorias e items
  }, []);


  const _tempComenzar = () => {
  
    // Guardar la data en la base de datos
    // (Estatus: COTIZADO)
    // tu lógica de guardado aquí...
  
    // Redirige al home
    setRouter('/'); // o a la ruta que definas como home
  
    // Inicia el envío de WhatsApp después de un pequeño retraso para asegurar que la redirección ocurrió
    setTimeout(() => {
      const phoneNumber = "523316170833"; // Número de teléfono en formato internacional
      const message = encodeURIComponent("¡Hola! Me interesa realizar un pedido de uniformes por favor"); // Mensaje predefinido
      window.location.href = `https://wa.me/${phoneNumber}?text=${message}`;
    }, 1000); // Retraso de 1 segundo (ajusta según sea necesario)
  };

  const gotTo = () => {
    setRouter("/");
  };

  return (
    <div className="Cotizar">
      <div className="cardsContainer">
        <h1>Elige tus prendas</h1>

        <h2>Caballero</h2>
        {
          Caballeros.map(category => (
            <CardCategory 
              key={category?.id}
              id={category?.id}
              category={category?.category}
              title={category?.categoryTitle} 
              items={category?.items} 
            />
          ))
        }

        <h2>Dama</h2>
        {
          Damas.map(category => (
            <CardCategory 
              key={category?.id}
              id={category?.id}
              category={category?.category}
              title={category?.categoryTitle} 
              items={category?.items} 
            />
          ))
        }
      </div>

      <div className="actions">
        <button className="comenzar" onClick={_tempComenzar}>
          Comenzar Pedido
        </button>
        <button className="salir" onClick={gotTo}>
          Regresar
        </button>
      </div>
    </div>
  );
}
