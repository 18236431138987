import { useEffect } from "react";

//components
import CardItem from "../CardItem/CardItem";

//styles
import "./CardCategory.scss";

export default function ({ id, category, title, items }) {
  //aquí vamos a consultar las prendas de la categoría, que vienen desde el padre

  useEffect(() => {}, []);

  return (
    <div className="cardCategoryContainer">
      <div className="cardCategory">
        {/* arriba */}
        <h3>{title}</h3>
        <p>Selecciona tus opciones</p>

        {/* abajo */}
        <div className="horizontalContainer">
          {items.map((item) => (
            <CardItem 
              categoryId={id}
              category={category}
              
              key={item?.id}
              id={item?.id}

              title={item?.title}
              image={item?.image}
              colorOptions={item?.colorOptions}
              sizeOptions={item?.sizeOptions}
            />
          ))}
        </div>
      </div>
    </div>
  );
}