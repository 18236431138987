import React, { useState } from "react";

//components
import Home from "./pages/Home/Home";
import Cotizar from "./pages/Cotizar/Cotizar";
import Evento from "./pages/Evento/Evento";

function App() {
  const [Router, setRouter] = useState("/")
  //this component will be used for setting the global layout and placing components
  //aquí debe ir el enrutador tambien

  return (
    <div className="AppContainer">
      <div className="AppLayout">
        {
          Router == "/" &&
          <Home setRouter={setRouter} />
        }
        {
          Router == "/cotizar" &&
          <Cotizar setRouter={setRouter} />
        }
        {
          Router == "/evento" &&
          <Evento setRouter={setRouter} /> 
          // ingresa el codigo de tu evento -> Seleccionar mi uniforme || Gestionar pedido
        }
      </div>
    </div>
  );
}

export default App;
