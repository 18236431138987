import React, { useState } from "react";

//styles
import "./Evento.scss";


export default function ({ setRouter, clientProps, setClientProps }) {
  const [eventCode, setEventCode] = useState("")
  
  const goToHome = () => {
    setRouter("/")
  }

  return (
    <div className="Evento">
      <div className="mainSection">
        <h1>El futuro de los uniformes</h1>
        <input value={eventCode} onChange={e => setEventCode(e.target.value)} />
      </div>

      <div className="actions">
        <button className="cta" disabled={!eventCode} >Seleccionar mi uniforme</button>
        <button className="salir" onClick={goToHome}>
          Regresar
        </button>
      </div>
    </div>
  );
}
